<template>
  <div class="feedback">
    <Header>意见反馈</Header>
    <div class="bg">
      <div class="textarea">
        <textarea v-model="value"></textarea>
      </div>
      <div class="btn" @click="submit" :class="value ? 'active' : null">
        提交
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
    };
  },
  methods: {
    submit() {
      if (!this.value) return this.$toast('请输入内容');
      const query = {
        content: this.value,
        token: localStorage.getItem('token'),
      };
      this.$api.feedback(query).then((e) => {
        if (e.code == 0) {
          this.$router.back();
        }
        return this.$toast(e.msg);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.feedback {
  .bg {
    background: #f3f4f8;
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    text-align: center;
    padding: 20px;
    .textarea {
      width: 100%;
      textarea {
        width: 100%;
        border: none;
        outline: none;
        height: 200px;
        padding: 10px;
        font-size: 14px;
        box-sizing: border-box;
      }
    }
    .btn {
      width: 100%;
      height: 40px;
      font-size: 14px;
      background: #eeeeee;
      color: #666666;
      line-height: 40px;
      margin-top: 30px;
      border-radius: 4px;
      &.active {
        background: #0754d3;
        color: #fff;
      }
    }
  }
}
</style>
